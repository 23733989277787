
* ,
*:before,
*:after {
    box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
body{
    margin: 0px;
}
.mobile{
    display: none;
}
.desktop{
    display: block;
}
#map{
    width: 100%;
    height: 600px;
}
hr {
    margin: 40px 0;
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #e5e5e5;
}
.container_bg{
    /*background-color: #f8f8f8;*/

}
.header_logo img{
    margin: 22px 0px;
}
.header_inner{
    display: flex;
    margin: auto;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #222331;
    justify-content: space-between;
}
.contact p{
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
.contact_address{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}
.no_wrap{
    white-space: nowrap;
}
.header_inner a.tel_block{
    border: 1px solid #1C3C86;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding: 6px 14px 11px;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #1C3C86;
    text-align: center;
}
.header_phone span{
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #222331;
}

.header_call a{
    font-family: 'Formular';
    font-style: normal;
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
    color: #fff;
    text-decoration: none;
    background: #1C3C86;
    padding: 16px 39px 16px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    white-space: nowrap;
    border: 1px solid #1C3C86;
    transition: 0.2s linear;
    border-left: none;
}
.header_call a:hover{
    background: none;
    border: 1px solid #1C3C86;
    color: #1C3C86;border-left: none;
}
.header_call {
    margin-top: 40px;
}

.header_address {
    padding: 40px 7px;
    min-width: 223px;
}
.header_phone {
    padding: 22px;
    width: 249px;
    padding-left: 13px;
    padding-right: 0px;
    min-width: 271px;
    padding-top: 21px;
}

.nav a{
    color: #222331;
    text-decoration: none;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
}
header{
    height: 100px;
    background-color: #F8F8F8;
    position: fixed;
    width: 100%;
    top: 0;
}
.container {
    width: 69%;
    margin: auto;
}
.nav_link active {
    margin-left: 40px;
    text-align: center;
}
.nav_link {
    margin-left: 40px;
}
.nav {
    padding: 40px;
    margin-left: -10px;
    display: flex;
}

/*content*/
.container_content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 3% 0px 7%;
    padding-top: 154px;
}
.container_content img{
    width: 43%;
}
.content_title {
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    width: 100%;
    color: #1C3C86;
    margin-bottom: 24px;
    font-weight: 800;
    font-size: 54px;
    line-height: 64px;
    text-transform: uppercase;
    padding-top: 76px;
}
.content_text{
    margin: 20px 0px 90px 0px;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    padding-right: 50px;
}
.content{
    display: block;
    width: 100%;
}
.content_btn{
    padding: 30px 82px;
    background: #1C3C86;
    border-radius: 16px;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;
    white-space: nowrap;
    border: none;
    transition: 0.2s linear;
    outline:none;
}
.content_btn:hover{
    background: #2f4f9b;
    cursor: pointer;
}
.content_btn:active{
    background: #1c3679;
}
.call_btn:hover{
    color: #fff;
    background: #1c3679;
}
.services{
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.04em;
    color: #222331;
    padding: 0 1% 0 0;
    display: flex;
}
.services p{
    margin-top: 0px;
}
.services div{
    padding-left: 37px;
}
.services span{
    color: #979BBF;
}
.services p{
    margin-bottom: 60px;
}
.services_info{
    background: #1C3C86;
    margin-top: 6%;
    padding: 5.5% 0;
}
.services_info .services_info_header{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.04em;
    font-size: 52px;
    line-height: 60px;
    color: #FFFFFF;
    padding-right: 12%;
}

/* Базовый контейнер табов */

.services_info_tabs {
    margin: 57px auto;
}
.header_services_info_tabs{
    border: 1px solid #7E99D9;
    border-radius: 16px;
    padding: 2px 0px;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
}
.services_info_tabs  section {
    display: none;
}
#content-tab1{
    display: block;
}
.services_info_tabs  section li{
    font-family: 'Formular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin: 4px 0px;
    color: #FFFFFF;
    list-style-type: none;
}
.services_info_tabs  section li:before{
    content: "";
    width: 18px;
    background: url(/static/img/stroke.svg);
    height: 14px;
    position: absolute;
    margin-top: 5px;
}
.services_info_tabs  section li p{
    padding-left: 48px;
}
.services_info_tabs  section ul li span{
    color: #7E99D9;
}
.services_info_tabs section:nth-child(2){
    width: 60%;
}
.services_info_tabs section:before{
    content: "";
    background: url(/static/img/mark.svg) no-repeat;
    background-size: 500px 500px;
    position: absolute;
    width: 500px;
    height: 500px;
    right: 141px;
    margin-top: 10px;
}
header{
    z-index: 1;
}
.services_info_tabs section  p {
    color: #FFFFFF;
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    margin: 48px 0px;
    letter-spacing: -1.2px;
}
.services_info_tabs section li p span{
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #7E99D9;
}
.services_info_tabs section li p {
    font-family: 'Formular';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    color: #fff;
    letter-spacing: 0px;
    margin: 21px 0px;
}
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.services_info_tabs  input {
    display: none;
    position: absolute;
}
.services_info_tabs  label {
    border-radius: 12px;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #7E99D9;
    margin: 8px;
    padding: 20px 67px;
}
.services_info_tabs label:hover{
    background: #26468F;
    cursor: pointer;
}
.services_info_tabs  label:before {
    font-family: fontawesome;
    font-weight: normal;
    margin-right: 10px;
}

.services_info_tabs  input:checked + label {
    background: #FFFFFF;
    border-radius: 12px;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #1C3C86;
    margin: 8px;
    padding: 20px 67px;
}
@media screen and (max-width: 1720px) {
    .services p{
        font-size: 21px;
    }
}
@media screen and (max-width: 1600px) {
    .services p{
        font-size: 18px;
    }
}
@media screen and (max-width: 1500px) {
    .services p{
        margin-bottom: 10px;
        line-height: 26px;
    }
}

@media screen and (max-width: 1340px) {
    .services p{    margin-bottom: 40px;
        line-height: 35px;
        font-size: 21px;
    }
}
@media screen and (max-width: 680px) {
    .services_info_tabs  label {
        font-size: 0;
    }
    .services_info_tabs  label:before {
        margin: 0;
        font-size: 18px;
    }
}
@media screen and (max-width: 400px) {
    .services_info_tabs  label {
        padding: 15px;
    }
}
/*why*/
.why_header {
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 60px;
    letter-spacing: -0.04em;
    color: #979BBF;

    padding-top: 103px;
}
.why_content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 4%;
}
.why_item {
    width: 50%;
    display: flex;
    padding-bottom: 2.2%;
}
.why_item p {
    text-align: left;
    width: 528px;
    height: 56px;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #222331;
}
.why_item img{
    margin-right: 30px;
}
.help p, .urist_list p{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    width: 77%;
    margin-bottom: 7.4%;
}
.white_btn{
    padding: 28px 90px;
    background: #FFFFFF;
    border-radius: 16px;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    color: #1C3C86;
    text-decoration: none;
    transition: 0.2s linear;
}
.white_btn:hover{
    background: #1C3C86;
    color: #FFFFFF;
    cursor: pointer;
}
.help{
    /*height: 356px;*/
    background-color: #1C3C86;
    background-image: url(/static/img/help_bg.png);
    margin-top: 3%;
    border-radius: 33px;
    padding: 0.4% 2.1%;
}
.help p{
    margin-bottom: 60px;
}
.help p span{
    background-color: #405da0;
    border-radius: 7px;
    padding: 0 7px;
    white-space: nowrap;
}
.help .white_btn{
    display: inline-block;
    margin-bottom: 30px;
}

/*urist_list container*/
.urlist_title{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 60px;
    color: #979BBF;
    padding-top: 106px;
    width: 80%;
    letter-spacing: -0.04em;
}
.urist_list p {
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #222331;
    letter-spacing: 0em;
    margin-bottom: 4.6%;
}
.urist {
    display: flex;
    border: 1px solid #ECECEC;
    border-radius: 30px;
    padding: 2%;
    margin-bottom: 23px;
}
.urist img {
    width: 304px;
    border-radius: 15px;
    margin-right: 20px;
    vertical-align: top;
    /*height: fit-content;*/
}
.urist_list .urist p{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #222331;
    margin: 0px 0% 6px;
    width: 100%;
}
.urist_list .urist > div{
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.015em;
    color: #979BBF;
}
.urist ul{
    padding-left: 20px;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.015em;
    color: #222331;
    margin-top: 28px;
    margin-bottom: 6px;
}
.urist ul li{
    width: 82%;
    line-height: 23px;
    margin-bottom: 15px;
}
.urist ul li:nth-of-type(n+4) {
    display: none;
}
.show_more:after{
    content: "";
    position: absolute;
    background-image: url("/static/img/more_icon.svg");
    width: 14px;
    height: 9px;
    margin-top: 10px;
    margin-left: 15px;
}
.show_more:hover{
    color: #1C3C86;
}
.show_more:hover:after{
    background-image: url("/static/img/more_icon_dark.svg");
}
/*modal form*/
.dm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    overflow: auto;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
.dm-overlay.target {
    display: block;
    /* анимация и время задержки */
    -webkit-animation: fade .6s;
    -moz-animation: fade .6s;
    animation: fade .6s;
}
.dm-table {
    display: table;
    width: 100%;
    height: 100%;
}
.dm-cell {
    display: table-cell;
    padding: 0 1em;
    vertical-align: middle;
    text-align: center;
}
.dm-modal {
    display: inline-block;
    background: #fff;
    -webkit-box-shadow: 0px 15px 20px rgb(0 0 0 / 22%), 0px 19px 60px rgb(0 0 0 / 30%);
    -moz-box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.22), 0px 19px 60px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 15px 20px rgb(0 0 0 / 22%), 0px 19px 60px rgb(0 0 0 / 30%);
    -webkit-animation: fade .8s;
    -moz-animation: fade .8s;
    animation: fade .8s;
    width: 30%;
    text-align: center;
}


@-moz-keyframes fade {
    from { opacity: 0; }
    to { opacity: 1 }
}
@-webkit-keyframes fade {
    from { opacity: 0; }
    to { opacity: 1 }
}
@keyframes fade {
    from { opacity: 0; }
    to { opacity: 1 }
}
.request_form{
    text-align: center;
    background-color: #ffffff;
    width: 520px;
    border-radius: 24px;
    padding: 25px 32px 17px;
}
.request_form_header{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #1C3C86;
}
.request_form p{
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    color: #959595;
    margin: 12px 0px 36px;
}
.request_form_field{
    background: #F8F8F8;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 16px;
    width: 100%;
    padding: 20px;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #222331;
    margin-bottom: 20px;
    outline: none;
}
.request_form_field.error{
    border: 1px solid #ff9c9c;
}
.request_form_field:last-child{
    min-height: 220px;
}
.modal_close_icon{
    float: right;
}
#request_form{
    display: none;
}
.request_form .content_btn{
    width: 100%;
}
.request_form .policy{
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #979BBF;
    width: 69%;
    margin: 15px auto 2px;
}
.request_form  .policy a{
    color: #979BBF;
    text-decoration: none;
}
#thanks,#callback{
    display: none;
}
.thanks{
    background: #1C3C86;
    width: 100%;
    height: 100%;
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    padding: 24px 24px 68px 24px;
}
.thanks .thanks_header{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    margin: 32px auto 0px;
}
.thanks .modal_close_icon{
    background-color: #ffffff;
    width: 72px;
    height: 72px;
    border-radius: 100%;
}
.thanks .modal_close_icon img{
    margin-top: 29px;
}
.thanks p{
    margin: 8px auto 0px;
}
.thanks .social_buttons a:last-child{
    margin-right: 0px;
}
.thanks .social_buttons a{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    text-decoration: none;
    margin-right: 58px;
    display: inline-flex;
}
/*.thanks .social_buttons a:after{*/
/*    content: "";*/
/*    position: absolute;*/
/*    width: 12px;*/
/*    height: 11px;*/
/*    background-image: url("/static/img/social_icon.svg");*/
/*    top: 7px;*/
/*    margin-left: 10px;*/
/*}*/
.thanks .social_buttons{
    position: fixed;
    bottom: 9%;
    left: 50%;
    left: 50%;
    transform: translateX(-50%);
}
.thanks  .thanks_img{
    background-color: #2f4f9b;
    padding: 56px;
    border-radius: 100%;
    width: 144px;
    height: 144px;
    margin: auto;
    margin-top: 10px;
}
.thanks  .thanks_img img{
    width: 42px;
    height: 33px;
}
.thanks .thanks_text{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.contact .social_buttons a:last-child{
    margin-right: 0px;
}
.contact .social_buttons a{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #1C3C86;
    text-decoration: none;
    margin-right: 77px;
    position: relative;
    display: inline-flex;
}
.contact .social_buttons a:hover:after{
    background-image: url("/static/img/social_icon_dark_hover.svg");

}
.social_buttons a span{
    padding-top: 4px;
    padding-left: 10px;
}
.img_contacts {
    display: inline-block;
    background-color: #fff;
}
/*.contact .social_buttons a:after{
    content: "";
    position: absolute;
    width: 13px;
    height: 11px;
    background-image: url("/static/img/social_icon_dark.svg");
    top: 7px;
    margin-left: 10px;
}*/
.contact .social_buttons a:hover{
    color: #7E99D9;
}
.contact .contact_header{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 60px;
    letter-spacing: -0.04em;
    color: #979BBF;
    margin-top: 102px;
}
.contact p{
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #222331;
    margin-top: 22px;
    width: 61%;
}
.contact .contact_address p{
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 0px;
    color: #222331;
    margin-top: 55px;
    margin-bottom: 51px;
}
.contact{
    margin-bottom: 103px;
}
footer{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}
footer .left_block div,footer .left_block a{
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #959595;
    margin-right: 79px;
    text-decoration: none;
}
footer .left_block{
    display: flex;
    justify-content: space-between;
}
footer .right_block a{
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #1C3C86;
    margin-left: 66px;
    text-decoration: none;
    white-space: nowrap;
}
@media (max-width: 1350px) {
    .container{
        width: 100%;
        padding: 0px 16px;
    }
}
@media (max-width: 1250px) {
    .nav{
        padding: 40px 5px;
    }
    .nav_link{
        margin-left: 10px;
    }
    .header_phone{
        min-width: 282px;
    }
    .services_info_tabs section:before{
        background: url(/static/img/mark.svg) no-repeat right;
        background-size: 300px 300px;
    }
    .content_title{
        font-size: 26px;
        width: 100%;
        line-height: 32px;
    }
    .content_text{
        font-size: 16px;
        padding-right: 0px;
    }
    .container_bg{
        padding-top: 30px;
    }

    .container_content{
        margin-top: 100px;
    }
    .services p{
        font-size: 16px;
        margin-top: 60px;
        line-height: 32px;
    }
    .services_info .services_info_header{
        font-size: 26px;
        line-height: 32px;
    }
    .services_info_tabs{
        margin: 24px auto;
    }
    .services_info_tabs input:checked + label{
        font-size: 16px;
        line-height: 24px;
        padding: 12px;
    }
    .services_info_tabs label{
        font-size: 16px;
        line-height: 24px;
        padding: 12px;
    }
    .help p, .urist_list p{
        font-size: 32px;
        line-height: 32px;
        padding-top: 25px;
    }
    .urlist_title{
        font-size: 32px;
        line-height: 32px;
        padding-top: 56px;
    }
    .services_info{
        padding: 44px 0;
    }
    .why_header{
        font-size: 32px;
        line-height: 32px;
        padding-top: 44px;
    }
    .why_item p{
        font-size: 18px;
    }
    .urist_list .urist p{
        padding-top: 0px;
    }
    footer{
        flex-wrap: wrap;
    }
    footer .right_block {
        margin-bottom: 20px;
        width: fit-content;
        margin: 20px auto 0px;
        order: -1;
    }
    footer .left_block {
        display: flex;
        justify-content: center;
        margin: 20px 0px;
        text-align: center;
        width: 100%;
        flex-wrap: wrap;
    }
}
@media (max-width: 1080px) {
    .header_logo img{
        margin: 11px 0px;
        width: 181px;
    }
    .services{
        display: block;
    }
    .services img{
        width: 100%;

    }
    .services div{
        padding-left: 0px;
    }
    .mobile{
        display: block;
    }
    header{
        height: 57px;
    }
    .container_content {
        margin-top: 56px;
        padding-bottom: 90px;
    }
    .menu-triger {
        background: url("/static/img/menu.svg") 0 0 repeat;
        display: block;
        width: 25px;
        height: 27px;
        position: absolute;
        right: 15px;
        top: 11px;

    }
    .menu-close {
        background: url("/static/img/close.svg") 0 0 repeat;
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 100;
    }
    .menu-popup {
        background: #fff;
        display: none;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0;
        box-sizing: border-box;
        padding: 37px;
        z-index: 9999;
    }
    .menu-popup div {
        margin: 0;
        padding: 0;
    }
    .menu-popup div {
        list-style: none;
        font-family: 'Halvar Breitschrift';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        padding: 0;
        color: #222331;
        margin-bottom: 16px;
    }
    .menu-popup div a.blue {
        font-family: 'Halvar Breitschrift';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: -0.04em;
        color: #1C3C86;
    }
    .menu-popup div:nth-child(4){

        margin-bottom: 50px;
    }
    .menu-popup div a.blue:last-child{
        margin-bottom: 50px;
    }
    .menu-popup div a.blue:first-child{
        margin-top: 66px;

    }
    .menu-popup div a.sicoal:hover:after{
        background-image: url("/static/img/social_icon_dark_hover.svg");

    }
    /*.menu-popup div a.sicoal:after{*/
    /*    content: "";*/
    /*    position: absolute;*/
    /*    width: 12px;*/
    /*    height: 11px;*/
    /*    background-image: url("/static/img/social_icon_dark.svg");*/
    /*    margin-top: 9px;*/
    /*    margin-left: 10px;*/
    /*}*/
    .menu-popup div a.sicoal span{
        padding-top: 4px;
        padding-left: 10px;
    }
    .menu-popup div a.sicoal {
        font-family: 'Halvar Breitschrift';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #1C3C86;
        display: flex;
    }
    .menu-popup div a {
        font-family: 'Halvar Breitschrift';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #222331;
        text-decoration: none;
    }
    .header_address,.nav,.header_phone,.header_call {
        display: none;
    }

    .menu-popup div:first-child{
        margin-top: 60px;
    }
}
@media (max-width: 800px) {
    .desktop{
        display: none;
    }
    header{
        background-color: #fff;
    }
    .why_content{
        padding-top: 17px;
    }
    .services p{
        font-family: 'Formular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
    .services_info_tabs section p{
        ont-family: 'Halvar Breitschrift';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.01em;
    }
    .services_info_tabs section li p span{
        font-family: 'Formular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #7E99D9;
    }
    .services_info_tabs section li p{
        font-size: 16px;
        line-height: 22px;
    }
    .container_content{
        height: auto;
        flex-wrap: wrap;
    }
    .help p,  .urist_list p,.urlist_title,.why_header{
        font-size: 26px;
    }
    .why_item p{
        font-size: 16px;
    }
    .container_content img{
        order: -1;
        width: 100%;
    }
    .content_title{
        font-family: 'Halvar Breitschrift';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;

        letter-spacing: -0.04em;
        text-transform: uppercase;
    }
    .content_text {
        font-size: 16px;
        padding-right: 0px;
        line-height: 22px;
        margin-bottom: 32px;
    }
    .services_info_tabs input:checked + label,
    .services_info_tabs input + label{
        white-space: nowrap;
    }
    .content_btn{
        font-size: 16px;
        width: 100%;
        line-height: 0px;
        display: inline-block;
        padding: 29px;
    }
    .request_form .policy{
        width: 96%;
    }
    .thanks .modal_close_icon img{
        margin-top: 0px;
        right: 0px;
        margin-right: -51px;
        filter:url('/static/img/close_white.svg');
    }
    .services p{
        margin-top: 44px;
        margin-bottom: 44px;

    }
    .urist{
        flex-wrap: wrap;
    }
    .urist img{
        width: 100%;

    }
    .why_item p{
        line-height: 21px;
        margin: 10px 0;
    }
    .why_item{
        width: 100%;
        padding-bottom: 27px;
    }
    .why_item img{
        margin-right: 16px;
        width: 40px;
    }
    .help p{
        font-size: 20px;
        line-height: 28px;
        width: 296px;
        margin-bottom: 80px;
        font-weight: 500;
    }
    .white_btn{
        display: inline-block;
        width: 100%;
        font-size: 16px;
        line-height: 0px;
        padding: 26px;
        border-radius: 12px;
    }
    .request_form, .thanks{
        height: 100%;
        width: 100%;
        left: 0;
    }
    .help{
        border-radius: 16px;
    }
    .urist_list p{
        font-size: 16px;
        line-height: 22px;
        margin-top: 0px;
        padding-top: 13px;
    }
    .urist_list .urist p{
        font-size: 20px;
        line-height: 28px;
        margin-top: 23px;
        margin-bottom: 0px;
    }
    .urist ul{
        margin-top: 13px;
    }
    .urist ul li{
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        width: 100%;
    }
    .show_more.rotate{
        width: auto;
    }
    .show_more{
        text-align: center;
        margin: 25px;
        width: fit-content;
    }
    .contact .contact_header{
        margin-top: 44px;
        font-size: 26px;
        line-height: 32px;
    }
    .contact p{
        font-size: 16px;
        line-height: 22px;
        margin-top: 15px;
        width: 100%;
    }
    .contact .contact_address p{
        width: 100%;
        font-family: 'Halvar Breitschrift';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #222331;
        margin-top: 34px;
        margin-bottom: -7px;
    }
    .contact .social_buttons a{
        width: fit-content;
        display: inline-flex;
        margin-bottom: 24px;
    }
    .contact .social_buttons{
        margin-top: 28px;
    }
    .urlist_title{
        padding-top: 46px;
        font-size: 26px;
        width: 100%;
    }
    footer .right_block{
        flex-wrap: nowrap;
        margin: 28px auto;
    }
    footer .right_block a{
        margin-left: 0px;
        margin-right: 19px;
    }
    footer .right_block a:last-child{
        margin-right: 0px;

    }
    footer .left_block div, footer .left_block a{
        margin-right: 0px;
    }
    footer .left_block a.policy{
        order: -1;
        margin-bottom: 13px;
    }
    footer .left_block{
        text-align: left;
        justify-content: initial;
    }
    .thanks .modal_close_icon{
        background: none;
    }
    .dm-cell{
        padding: 0;
    }
    .thanks .thanks_img{
        width: 96px;
        height: 96px;
        padding: 29px;
    }
    .thanks .thanks_header,.request_form_header{
        font-size: 26px;
        line-height: 32px;
    }
    .thanks p{
        font-size: 16px;
        line-height: 22px;
    }
    .thanks .social_buttons{
        display: none;
    }
    .request_form p{
        font-size: 16px;
        line-height: 22px;
    }
    .request_form{
        padding: 16px;
    }
    .request_form_field{
        font-size: 16px;
        line-height: 22px;
        padding: 15px;
        border-radius: 12px;
    }
    .help_container{
        margin: 0 16px;
    }
    #request_form,#thanks{
        width: 100%;
    }
    .request_form {
        padding: 16px;
        border-radius: 0;
    }
    .thanks .thanks_text{
        left: 50%;
        width: 76%;
    }
    .container_bg{
        background:none;
        padding-top: 10px;
    }
    .services_info_tabs section:nth-child(2){
        width: 100%;
    }
    .urist{
        border-radius: 16px;
    }
    .urist img{
        border-radius: 12px;
    }
    .header_services_info_tabs{
        border: none;
    }
    .services_info_tabs input + label{
        background: #26468F;
    }
    .header_logo img{
        margin: 7px 0px;
        width: 181px;
    }
    .request_form .content_btn{
        display: inline-block;
        padding: 30px;
    }
    #map{
        width: 100%;
        height: 400px;
    }
    .services_info_tabs section:before{
        background: none;
    }
    .header_inner{
        z-index: 9999;
    }
    .show_more:after{
        z-index: -2;
    }
    .services_info_tabs section li{
        font-weight: 500;
    }
    header{
        z-index:2;
    }
}
@media (max-width: 600px){
    .contact .social_buttons, .menu_flex_column{
        display: flex;
        flex-direction: column;
    }
    .contact_address,.contact .contact_address p{
        font-size: 16px;
    }
}
@media (max-width: 400px){
    .contact_address div{
        display: flex;
        flex-direction: column;
    }
    .menu_flex_column{
        font-size: 17px !important;
    }

}
.textarea{
    height: 200px;
}
.show_more:hover{
    cursor: pointer;
}
.show_more.rotate:after{
    transform: rotate(
        180deg
    );
}
.services {
    align-items: flex-start;
}
.urist {
    align-items: flex-start;
}
